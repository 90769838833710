/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b63aafc9-a936-43ae-b004-5c83fd587b26",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6Crx98Ujm",
    "aws_user_pools_web_client_id": "42mcd1nap7ki392cl1i2rraldr",
    "oauth": {
        "domain": "faceclock12081f40-12081f40-dem.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://faceregdem.dimodigital/lk/",
        "redirectSignOut": "http://localhost:3000/,https://faceregdem.dimodigital.lk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "face-clock-bucket-dem",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "userapi",
            "endpoint": "https://iigr0n9oz3.execute-api.us-east-1.amazonaws.com/dem",
            "region": "us-east-1"
        },
        {
            "name": "faceregapi",
            "endpoint": "https://j4q65bptwa.execute-api.us-east-1.amazonaws.com/dem",
            "region": "us-east-1"
        },
        {
            "name": "savetodbapi",
            "endpoint": "https://46zj22vd68.execute-api.us-east-1.amazonaws.com/dem",
            "region": "us-east-1"
        }
    ],
    "predictions": {
        "identify": {
            "identifyEntities": {
                "proxy": false,
                "region": "us-east-1",
                "celebrityDetectionEnabled": true,
                "defaults": {
                    "collectionId": "clockAttendance-dem",
                    "maxEntities": 1
                }
            }
        }
    }
};


export default awsmobile;
